<template>
  <aside>
    <div class="ReportMenu" ref="reportmenu">
      <div class="ReportMenu_Section">
        <ButtonSet>
          <LinkButton :aria-current="$router.currentRoute.value.name == 'ReportsView' || $router.currentRoute.value.name == 'ReportsViewVersion'" :icon="['solid', 'home']" type="transparent" direction="vertical" size="small" :to="report?`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}`:'#'">{{$gettext('Assessment Home')}}</LinkButton>
        </ButtonSet>
      </div>
      <div class="ReportMenu_Section">
        <ButtonSet>
          <LinkButton :aria-current="$router.currentRoute.value.name == 'ReportsPages' || $router.currentRoute.value.name == 'ReportsPagesVersion'" :icon="['regular', 'file']" type="transparent" direction="vertical" size="small" :to="report?`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/pages`:'#'" :pill="report?report.pageCount.toString():'0'">{{$gettext('Pages')}}</LinkButton>
          <LinkButton :aria-current="$router.currentRoute.value.name == 'ReportsComponents' || $router.currentRoute.value.name == 'ReportsComponentsVersion'" :icon="['solid', 'puzzle-piece']" type="transparent" direction="vertical" size="small" :to="report?`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/components`:'#'" :pill="report?report.componentCount.toString():'0'">{{$gettext('Components')}}</LinkButton>
          <LinkButton :aria-current="$router.currentRoute.value.name == 'ReportsJourneys' || $router.currentRoute.value.name == 'ReportsJourneysVersion'" :icon="['solid', 'route']" type="transparent" direction="vertical" size="small" :to="report?`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/journeys`:'#'" :pill="report?report.userJourneyCount.toString():'0'">{{$gettext('User Journeys')}}</LinkButton>
          <LinkButton :aria-current="$router.currentRoute.value.name == 'ReportsIssues' || $router.currentRoute.value.name == 'ReportsIssuesVersion'" :icon="['solid', 'circle-exclamation']" type="transparent" direction="vertical" size="small" :to="report?`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/issues`:'#'" :pill="report?report.issueCount.toString():'0'">{{$gettext('Issues')}}</LinkButton>
        </ButtonSet>
      </div>
      <div class="ReportMenu_Section" v-if="report&&hasPermission('QualityOfLife','SpreadsheetInReportMenu')">
        <ButtonSet>
          <LinkButton :icon="['solid', 'table']" type="transparent" direction="vertical" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/spreadsheet`">{{$gettext('Spreadsheet view')}}</LinkButton>
        </ButtonSet>
      </div>
      <div class="ReportMenu_Section" v-if="report">
        <ButtonSet>
          <LinkButton :icon="['solid', 'cog']" type="transparent" direction="vertical" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/settings/`">{{$gettext('Settings')}}</LinkButton>
          <!-- <LinkButton :icon="['solid', 'tools']" type="transparent" direction="vertical" size="small" :to="`/reports/${report.identifier}/${report.currentVersion.version}/publish`" v-if="!publishDisabled">{{$gettext('Publish and share')}}</LinkButton> -->
          <!-- <LinkButton :icon="['regular', 'clone']" type="transparent" direction="vertical" size="small" :to="`/reports/${report.identifier}/versions`">{{$gettext('Report Versions')}}</LinkButton> -->
          <Button direction="vertical" size="small" v-if="report.archived && !hasPermission('Restrictions','CanNotArchiveAndRestoreProducts')" type="transparent" @click="restoreReport" :icon="['solid', 'archive']">{{$gettext('Restore assessment')}}</Button>
          <Button direction="vertical" size="small" v-if="!report.archived && !hasPermission('Restrictions','CanNotArchiveAndRestoreProducts')" type="transparent" @click="archiveReport" :icon="['solid', 'archive']">{{$gettext('Archive assessment')}}</Button>
          <LinkButton :icon="['regular', 'file']" type="transparent" direction="vertical" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/statement`" v-if="!statementDisabled && hasPermission('QualityOfLife','AccessibilityStatementInReportMenu')">{{$gettext('View accessibility statement')}}</LinkButton>
          <LinkButton :icon="['regular', 'file']" type="transparent" direction="vertical" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/render`" v-if="!previewDisabled">{{$gettext('Preview assessment')}}</LinkButton>
          <LinkButton :icon="['solid', 'circle-check']" type="transparent" direction="vertical" size="small" @click="$refs.accreditationmodal.show(report._id)" v-if="hasPermission('Reports','Accredit')">{{$gettext('Accredit assessment')}}</LinkButton>
          <Button v-if="csvExport&&report" direction="vertical" size="small" type="transparent" @click="$refs.csvreportmodal.show(report.currentVersion._id, report.currentVersion.version)" :icon="['solid', 'archive']">{{$gettext('Export csv of issues')}}</Button>
          <Button direction="vertical" size="small" v-if="user.aclevel<=1 && !hasPermission('Restrictions','CanNotCloneProducts')" type="transparent" @click="$refs.clonemodal.show(report._id)" :icon="['solid', 'copy']">{{$gettext('Clone assessment')}}</Button>
          <Button direction="vertical" size="small" v-if="hasJira && isManager" type="transparent" @click="$refs.jiraexportmodal.show()" :icon="['solid', 'share-square']">{{$gettext('Export to Jira')}}</Button>
        </ButtonSet>
      </div>
      <div class="ReportMenu_Section" v-if="report && report.team">
        <ButtonSet>
          <Button v-if="report.team" direction="vertical" size="small" type="transparent" @click="$refs.ticketmodal.show()" :icon="['solid', 'ticket-alt']">{{$gettext('Open a ticket')}}</Button>
          <!-- <LinkButton direction="vertical" size="small" type="transparent" :to="`/feedback`" :icon="['solid', 'comment-alt']" target="_blank">{{$gettext('Send feedback')}} <Icon type="solid" icon="external-link-alt" read="(Opens in new tab)" /></LinkButton> -->
        </ButtonSet>
      </div>
      <!-- <div class="ReportMenu_Section" v-if="report">
        <ButtonSet>
          <Button :icon="['regular', 'eye-slash']" type="transparent" direction="vertical" size="small" @click="$emit('close')">{{$gettext('Hide menu')}}</Button>
        </ButtonSet>
      </div> -->
    </div>
    <template v-if="report">
      <CloneReportModal ref="clonemodal"/>
      <TicketModal v-if="report.team" ref="ticketmodal" :defaultTeam="report.team._id" :defaultReport="report._id"/>
      <JiraExportModal v-if="report.team && hasJira" :report="report" ref="jiraexportmodal" />
      <CsvReportModal ref="csvreportmodal" />
      <AccreditationDetailsModal ref="accreditationmodal" />
    </template>
  </aside>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';

import CloneReportModal from '@/modals/Report/Clone';
import TicketModal from '@/modals/Ticket/Create';
import JiraExportModal from '@/modals/Jira/ExportReport';
import CsvReportModal from '@/modals/CSV/Report';
import AccreditationDetailsModal from '@/modals/Report/EditAccreditationDetails';

export default {
  name: 'ReportMenu',
  mounted() {
    window.addEventListener( 'scroll', this.scroll );
    this.offset = this.$refs.reportmenu.getBoundingClientRect().top;
    if( this.offset < 100 ) {
      this.offset = 240;
    }
    this.$apollo.queries.report.refetch();
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            disablePublishAndShare
            disablePreviewReport
            disableAccessibilityStatement
            csvExport
          }
        }
      `,
    } ).then( res => {
      this.publishDisabled = res.data.settings.disablePublishAndShare;
      this.previewDisabled = res.data.settings.disablePreviewReport;
      this.statementDisabled = res.data.settings.disableAccessibilityStatement;
      this.csvExport = res.data.settings.csvExport;
    } ).catch( () => {
      this.$alerts.coded( 'E001', 'F301' ); //see notifications spreadsheet
    } );
  },
  beforeUnmount() {
    window.removeEventListener( 'scroll', this.scroll );
  },
  data() {
    return {
      publishDisabled: false,
      previewDisabled: false,
      statementDisabled: false,
      csvExport: false,
      report: false,

      showPages: false,
      showComponents: false,
      hasJira: false,

      offset: 0,
    };
  },
  methods: {
    reload() {
      this.$apollo.queries.report.refetch();
    },
    scroll( e ) {
      const fromTop = this.offset - window.scrollY;
      if( fromTop <= 100 ) {
        this.$refs.reportmenu.classList.add( '_fixed' );
      } else {
        this.$refs.reportmenu.classList.remove( '_fixed' );
        this.offset = this.$refs.reportmenu.getBoundingClientRect().top;
      }
    },
    togglePages() {
      this.showPages = !this.showPages;
    },
    toggleComponents() {
      this.showComponents = !this.showComponents;
    },
    archiveReport() {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          report: archiveReport(id: $id) {
            archived
          }
        }`,
        // Parameters
        variables: {
          id: this.report._id,
        },
      } ).then( res => {
        this.report.archived = res.data.report.archived;
        this.$emit( 'changes' );
        this.$alerts.success( 'Report has been archived' );
      } ).catch( () => {
        this.$alerts.coded( 'E011', 'F302' ); //see notifications spreadsheet
      } );
    },
    restoreReport() {
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          report: unarchiveReport(id: $id) {
            archived
          }
        }`,
        // Parameters
        variables: {
          id: this.report._id,
        },
      } ).then( res => {
        this.report.archived = res.data.report.archived;
        this.$emit( 'changes' );
        this.$alerts.success( 'Report has been unarchived' );
      } ).catch( () => {
        this.$alerts.coded( 'E012', 'F303' ); //see notifications spreadsheet
      } );
    },
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( {
      user: 'user',
      reportId: 'report',
    } ),
    isManager() {
      return this.report.team.managers.map( user => user._id ).indexOf( this.user.id ) >= 0;
    },
    ...mapState( [ 'user' ] ),
  },
  watch: {
    report() {
      if( this.report.team ) {
        this.$apollo.query( {
          query: gql`
            query TeamHasJiraSet($id: ObjectID!) {
              hasJira: TeamHasJiraSet(id: $id)
            }
        `,
        variables: {
            id: this.report.team?._id,
          },
        } ).then( res => {
          this.hasJira = res.data.hasJira;
        } ).catch( () => {
          this.$alerts.coded( 'E013', 'F304' ); //see notifications spreadsheet
        } );
      }
    },
  },
  apollo: {
    report: {
      query: gql`
        query ReportFromVersion($id: ObjectID!) {
          report: ReportFromVersion(id: $id) {
            _id
            identifier
            archived
            accreditation {
              active
              pass
              shownDate
              shownStandard
            }
            pageCount
            componentCount
            userJourneyCount
            issueCount
            currentVersion {
              _id,
              version
            }
            team {
              _id,
              identifier,
              managers {
                _id
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.reportId,
        };
      },
    },
  },
  components: {
    CloneReportModal,
    TicketModal,
    JiraExportModal,
    CsvReportModal,
    AccreditationDetailsModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_menu.scss';

  .ReportMenu {
    width: calc(#{$hugr-body-menu-width-open} - 1px);
    border-right: 1px solid $hugr-colours-grey;
    // position: fixed;
    height: calc(100% - 105px);
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: lighten($hugr-colours-primary, 60%);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: lighten($hugr-colours-primary, 30%);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $hugr-colours-primary;
    }

    &._fixed {
      position: fixed;
      top: 100px;
    }

    &_Section {
      border-bottom: 1px solid $hugr-colours-grey;
      padding: 20px 0;
      margin-right: 15px;

      a, button {
        font-size: 0.8em;
      }

      &_ListLinks {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          a {
            padding: 2px 5px;
            width: 100%;
            display: inline-block;
            color: $hugr-colours-primary;
            border: 1px solid transparent;
            transition: all .5s ease-in-out 0s;
            &:hover, &:focus, &.focus {
              background: $hugr-colours-grey;
              border-radius: 10px;
              // color: $hugr-colours-tertiary;
              // border: 1px solid $hugr-colours-tertiary;
              outline: none;
            }
          }
        }
      }

      &_AccordionHead {
        position: relative;
        padding: 10px 0;
        cursor: pointer;
        summary {
          list-style: none;
          font-family: "Quicksand", serif;
          display: flex;
          padding: 10px;
          border: 1px solid transparent;
          transition: all .5s ease-in-out 0s;
            &:hover, &:focus{
              background: $hugr-colours-grey;
              border-radius: 10px;
              outline: none;
            }
            span {
              margin: 0;
              flex: 1 1 95%;
            }
            svg {
              flex: 1 1 5%;
            }
        }
        summary::-webkit-details-marker {
          display: none;
        }
      }

      &_AccordionBody {
        &._hide {
          display: none;
        }
      }
    }
  }

  ._darkMode .ReportMenu {
    &_Section {

      &_ListLinks {
        li {
          a {

            color: $hugr-colours-grey;
            &:hover, &:focus, &.focus {
              background: darken($hugr-colours-primary, 10%);
            }
          }
        }
      }

      &_AccordionHead {
        summary {
          &:hover, &:focus{
            background: darken($hugr-colours-primary, 10%);
          }
        }
      }
    }
  }

  @media (max-height: 850px) {
    .ReportMenu {
      &_Section {
        padding: 10px 0;

      }
    }
  }
</style>
