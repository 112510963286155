<template>
  <ReportInfo :trail="[
        {name: $gettext('Home'), path: '/dashboard'},
        {name: $gettext('Assessments'), path: `/${$hugrConfig.reportRouterReplacement}s`},
        {name: 'THIS_REPORT'},
        {name: $gettext('Issues'), path: `/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/issues`}
      ]" />

  <Body :withMenu="true" class="Issues" title="Issues" :titleIcon="['solid', 'circle-exclamation']" :titleHint="['',$gettext('These are all the issues identified as part of this assessment.')]">
    <div class="Issues_Btns" v-if="!reportLoading">
      <Button v-if="!report?.currentVersion.published&&!disableAddIssue" class="Issues_Btns_Add"  :icon="['solid', 'plus']" size="small" type="secondary" @click="issueinstancemodal.show()">{{ $gettext('Add an issue') }}</Button>
      <LinkButton class="Issues_Btns_Spreadsheet" :icon="['solid', 'table']" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/spreadsheet`">Spreadsheet view</LinkButton>
    </div>

    <template v-if="!reportLoading">
      <FilterBar ref="filterBar" class="Issues_Filters" @clear="clearAllFilters" :filters="filters">
        <FilterOpt :title="$gettext('Issue status')" @change="(v) => { filters.status = v }"
          @open="(current) => { filterBar.setCurrent(current) }" :options="{
            'reported': $gettext('Reported'),
            'in-progress': $gettext('In Progress'),
            'retest': $gettext('To Retest'),
            'closed': $gettext( 'Closed' ),
          }" :extra="{
            'reported': $gettext('Issues that are reported and action is required.'),
            'in-progress': $gettext('Issues where a fix is in progress.'),
            'retest': $gettext('Issues that have been fixed and require retesting'),
            'closed': $gettext( 'Issues that have been marked as closed' ),
          }" />
        <FilterOpt :title="$gettext('Issue severity')" :default="filters.severity" @change="(v) => { filters.severity = v }"
          @open="(current) => { filterBar.setCurrent(current) }" :options="{
            '0': $gettext('Advisory'),
            '1': $gettext('Low'),
            '2': $gettext('Medium'),
            '3': $gettext('High'),
            '4': $gettext('Critical'),
          }" :extra="{
            '0': $gettext(`Issues that should be addressed to improve the user experience`),
            '1': $gettext(`There may be a minor impact for some users`),
            '2': $gettext(`Some people may have difficulty using the product`),
            '3': $gettext(`Issues that cause severe difficulties for some groups of people`),
            '4': $gettext(`Issues that make the product unusable`),
          }" />
        <FilterOpt :title="$gettext('Issue priority')" @change="(v) => { filters.priority = v }"
          @open="(current) => { filterBar.setCurrent(current) }" :options="{
            '0': $gettext('Lowest'),
            '1': $gettext('Low'),
            '2': $gettext('Medium'),
            '3': $gettext('High'),
            '4': $gettext('Highest'),
          }" :extra="{
            '0': $gettext(`Issues that should be fixed when convenient`),
            '1': $gettext(`Issues that require a fix in the long term`),
            '2': $gettext(`Issues that require a in the short term`),
            '3': $gettext(`Issues that require a fix urgently`),
            '4': $gettext(`Issues that require a fix immediately`),
          }" />
        <FilterOpt :title="$gettext('Assignee')" v-if="report && report.team"
          @change="(v) => { filters.assignee = v }" @open="(current) => { filterBar.setCurrent(current) }"
          :options="teamMembers" />
        <FilterOpt :title="$gettext('Reporter')" @change="(v) => { filters.reporter = v }"
          @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="reporters" />
        <FilterOpt :title="$gettext('QA status')" @change="(v) => { filters.flag = v }"
          @open="(current) => { $refs.filterBar.setCurrent(current) }" :options="{
            'unchecked': $gettext('Unchecked'),
            'to-check': $gettext('To Check'),
            'checked': $gettext('Checked'),
            'change-required': $gettext('Change Required'),
            'second-opinion': $gettext('Second Opinion')
          }" :extra="{
            'unchecked': $gettext(`Issue hasn't been checked`),
            'to-check': $gettext('Issue needs to be checked'),
            'checked': $gettext('Issue has been checked and confirmed'),
            'change-required': $gettext('A change is required before the issue can be confirmed'),
            'second-opinion': $gettext('Issue has been checked but a second opinion is required')
          }" />
        <!-- <FilterSort
          @open="() => { filterBar.closeAll() }"
          :options="{
            'date-oldest': 'Date added (oldest first)',
            'date-newest': 'Date added (newest first)',
            'instances-low': 'Instances (low to high)',
            'instances-high': 'Instances (high to low)',
            'issuetemplate': 'Issue template'
          }"
        /> -->
      </FilterBar>
      <h4 class="Issues_FilterAlert" aria-live="polite">{{$gettext('Showing')}} {{ issueCount }} {{$gettext('issues with')}} {{ instanceCount }} {{$gettext('instances')}}</h4>
      <div class="Issues_FauxTable">
        <div class="Issues_FauxTable_Head __grid" aria-hidden="true">
          <h4 class="__grid_column_9" aria-hidden="true">Issue Category</h4>
          <h4 class="__grid_column_1" aria-hidden="true"><span class="a11yhide">Has key issues</span></h4>
          <h4 class="__grid_column_1" aria-hidden="true">Instances</h4>
          <h4 class="__grid_column_1" aria-hidden="true">Severity</h4>
        </div>
        <ul class="Issues_FauxTable_Templates">
          <li v-for="template in issues" :key="template.template._id" class="Issues_FauxTable_Templates_Item">
            <div class="Issues_FauxTable_Templates_Item_Inner __grid">
              <p class="__grid_column_9">
                <button class="Issues_FauxTable_Templates_Item_Inner_Btn" :aria-label="`${template.open?'Collapse':'Expand'} ${template.template.identifier}: ${template.template.title}`" :title="template.open?'Collapse':'Expand'" @click="template.open=!template.open" :aria-expanded="template.open?'true':'false'">
                  <Icon type="solid" :icon="template.open?'chevron-up':'chevron-down'"></Icon>
                </button>
                <span class="Issues_FauxTable_Templates_Item_Inner_TemplateTitle" @click="template.open=!template.open">{{ template.template.identifier }}: {{ template.template.title }}</span>
              </p>
              <p class="__grid_column_1">
                <span v-if="template.key" title="Has key issues" class="__grid_column_1">
                  <Icon type="solid" icon="star" />
                  <span class="a11yhide">Has key issues</span>
                </span>
              </p>
              <p class="__grid_column_1"><span>{{ template.issues.length }}</span> <span class="a11yhide">instances</span></p>
              <p class="__grid_column_1">
                <span class="a11yhide">Severity: </span>
                <Pill :text="getSeverity( template.template.severity )" size="small" />
              </p>
            </div>
            <div class="Issues_FauxTable_Templates_Item_Sub" v-show="template.open">
              <div class="Issues_FauxTable_Templates_Item_Sub_Head __grid" aria-hidden="true">
                <h4 class="__grid_column_2" aria-hidden="true">Instance</h4>
                <h4 class="__grid_column_2" aria-hidden="true">Found on</h4>
                <h4 class="__grid_column_1" aria-hidden="true">Priority</h4>
                <h4 class="__grid_column_1" aria-hidden="true">Status</h4>
                <h4 class="__grid_column_2" aria-hidden="true">Reporter</h4>
                <h4 class="__grid_column_2" aria-hidden="true">QA Status</h4>
              </div>
              <ul class="Issues_FauxTable_Templates_Item_Sub_Issues">
                <template v-for="issue in template.issues" :key="issue._id">
                  <li v-if="issue.primary" class="Issues_FauxTable_Templates_Item_Sub_Issues_Item">
                    <div class="Issues_FauxTable_Templates_Item_Sub_Issues_Item_Inner __grid">
                      <p class="__grid_column_2 _identifier"><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${report.identifier}/${report.currentVersion.version}/issues/${issue.identifier}`" @click.prevent="issuefragment.show( issue.identifier )" :title="issue.identifier">{{ issue.identifier }}</router-link></p>
                      <p class="__grid_column_2">
                        <span class="a11yhide">Found on: </span>
                        <span>
                          <template v-if="issue.page">
                            <span>{{ issue.page.name }}</span>
                            <span v-if="issue.others.length">,&nbsp;</span>
                            <span>{{ issue.others.map( i => i.page.name ).join( ', ' ) }}</span>
                          </template>
                          <template v-if="issue.component">
                            <span>{{ issue.component.identifier }}</span>
                          </template>
                        </span>
                      </p>
                      <p class="__grid_column_1">
                        <span class="a11yhide">Priority: </span>
                        <Pill :text="getPriority( issue.priority )" size="small" />
                      </p>
                      <p class="__grid_column_1"><span class="a11yhide">Status: </span><span>{{ issue.status.replace( '-', ' ') }}</span></p>
                      <p class="__grid_column_2"><span class="a11yhide">Reporter: </span><span>{{ issue.reporter.name }}</span></p>
                      <p class="__grid_column_2">
                        <span class="a11yhide">QA Status: </span>
                        <!-- <span>{{ issue.flag.replace( '-', ' ') }}</span> -->
                        <!-- <Pill v-if="issue.flag=='checked'" text="Checked" size="small" type="positive"/>
                        <Pill v-else-if="issue.flag=='change-required'" text="Change required" size="small" type="negative"/>
                        <Pill v-else-if="issue.flag=='second-opinion'" text="Second opinion" size="small" type="warning"/>
                        <Pill v-else-if="issue.flag=='unchecked'" text="Unchecked" size="small" />
                        <Pill v-else-if="issue.flag=='to-check'" text="To check" size="small" />
                        <Pill v-else :text="issue.flag.replace( '-', ' ')" size="small" /> -->
                        <span>{{ issue.flag.replace( '-', ' ' ).charAt(0).toUpperCase() + issue.flag.slice(1) }}</span>
                      </p>
                      <p v-if="issue.isAKeyIssueInstance" class="__grid_column_1" title="Key issue">
                        <Icon type="solid" icon="star" />
                        <span class="a11yhide">Key issue</span>
                      </p>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <Loader v-else />
  </Body>
  <IssueInstanceModal v-if="report" ref="issueinstancemodal" :report="report?.currentVersion._id" @success="cleanRefetch"/>

  <IssueFragment ref="issuefragment" @closed="closeFragment" @goToPage="goToPage" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney"/>
  <PageFragment ref="pagefragment" @closed="closeFragment" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
  <ComponentFragment ref="componentfragment" @closed="closeFragment" @goToPage="goToPage" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
  <UserJourneyFragment ref="userjourneyfragment" @closed="closeFragment" @goToComponent="goToComponent" @goToPage="goToPage" @goToIssue="goToIssue"/>
</template>

<script setup>
  import { ref, computed, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuery, useMutation } from '@vue/apollo-composable';
  import gql from 'graphql-tag';

  import IssueInstanceModal from '@/modals/IssueInstance/Create';

  import Pill from '../../components/UI/Pill.vue';

  import FilterBar from '@/components/UI/FilterBar.vue';
  import FilterOpt from '@/components/UI/FilterOpt.vue';

  import IssueFragment from '@/fragments/Issue';
  import PageFragment from '@/fragments/Page';
  import ComponentFragment from '@/fragments/Component';
  import UserJourneyFragment from '@/fragments/UserJourney';

  import config from '../../../config';

  const router = useRouter();
  const store = useStore();

  const identifier = ref( router.currentRoute.value.params.identifier );
  const version = ref( router.currentRoute.value.params.version );
  const urlinstance = ref( router.currentRoute.value.params.instance );
  
  const report = ref( null );
  const issues = ref( [] );

  const issueinstancemodal = ref( null );

  const issuefragment = ref( null );
  const pagefragment = ref( null );
  const componentfragment = ref( null );
  const userjourneyfragment = ref( null );

  const reportLoading = ref( true );

  onMounted( () => {
    if( urlinstance.value ) {
      issuefragment.value.show( urlinstance.value );
    }

    const { mutate: sendNavigation } = useMutation(
      gql`
        mutation sendNavigation ($page: String) {
          sendNavigation(page: $page)
        }
      `, {
        variables: {
          page: 'Page Fragment',
        },
    } );
    
    sendNavigation();
  } );

  const { onResult: onSettings } = useQuery(
    gql`
      query Settings {
        settings: Settings {
          id
          disableAddIssue
        }
      }
    `,
  );

  const disableAddIssue = ref( false );

  onSettings( ( { data } ) => {
    if( !data ) return;

    disableAddIssue.value = data.settings.disableAddIssue;
  } );

  const filterBar = ref( null );
  const filters = ref( {
    status: [],
    flag: [],
    severity: [],
    priority: [],
    assignee: [],
    reporter: [],
  } );

  const clearAllFilters = () => {
    filters.value = {
      status: [],
      flag: [],
      severity: [],
      priority: [],
      assignee: [],
      reporter: [],
    };
  };

  const reporters = computed( () => {
    if( !report.value ) return;
    
    const res = {};
    for( const issue of report.value.issuesWithClosed ) {
      if( issue.reporter && !Object.prototype.hasOwnProperty.call( res, issue.reporter._id ) ) {
        res[issue.reporter._id] = issue.reporter.name;
      }
    }
    // for( const issue of this.closedIssues ) {
    //   if( issue.reporter && !Object.prototype.hasOwnProperty.call( res, issue.reporter._id ) ) {
    //     res[issue.reporter._id] = issue.reporter.name;
    //   }
    // }

    return res;
  } );

  const teamMembers = computed( () => {
    if( !report.value ) return;

    const res = {};
    for( const person of report.value.team.users ) {
      res[person._id] = person.name;
    }
    //add all assignees of issue incase of changed team
    for( const issue of report.value.issuesWithClosed ) {
      if( issue.assignee && !Object.prototype.hasOwnProperty.call( res, issue.assignee._id ) ) {
        res[issue.assignee._id] = issue.assignee.name;
      }
    }

    return res;
  } );

  const filterIssues = () => {
    let ret = report.value.issuesWithClosed.filter( i => i.status.indexOf( 'closed' ) != 0 );

    if( filters.value.status.length ) {
      // if( filters.value.status.indexOf( 'showclosed' ) >= 0 ) {
      //   ret = report.value.issuesWithClosed;
      //   fixedFilters = fixedFilters.filter( f => f != 'showclosed' );
      // }
      if( filters.value.status.indexOf( 'closed' ) >= 0 ) {
        ret = report.value.issuesWithClosed;
      }

      ret = ret.filter( i => {
        const a = filters.value.status.indexOf( i.status ) >= 0;
        let b = false;
        if( filters.value.status.indexOf( 'closed' ) >= 0 ) {
          b = i.status.indexOf( 'closed' ) == 0;
        }

        return a || b;
      } );
    }

    if( filters.value.flag.length ) {
      ret = ret.filter( i => {
        return filters.value.flag.indexOf( i.flag ) >= 0;
      } );
    }

    if( filters.value.severity.length ) {
      ret = ret.filter( i => {
        return filters.value.severity.indexOf( i.template.severity.toString() ) >= 0;
      } );
    }

    if( filters.value.priority.length ) {
      ret = ret.filter( i => {
        return filters.value.priority.indexOf( i.priority.toString() ) >= 0;
      } );
    }

    if( filters.value.assignee.length ) {
      ret = ret.filter( i => {
        return i.assignee && filters.value.assignee.indexOf( i.assignee._id ) >= 0;
      } );
    }

    if( filters.value.reporter.length ) {
      ret = ret.filter( i => {
        return filters.value.reporter.indexOf( i.reporter._id ) >= 0;
      } );
    }

    const issuesByTemplate = {};

    for( const issue of ret ) {
      if( typeof issuesByTemplate[ issue.template._id ] == 'undefined' ) issuesByTemplate[ issue.template._id ] = { template: issue.template, issues: [], open: false, key: false };
      if( issue.isAKeyIssueInstance ) issuesByTemplate[ issue.template._id ].key = true;
      issuesByTemplate[ issue.template._id ].issues.push( issue );
    }

    issues.value = issuesByTemplate;
  };

  watch( filters, () => {
    if( report.value ) {
      filterIssues();
    }
  }, { deep: true } );

  const issueCount = computed( () => Object.values( issues.value ).map( t => t.issues.filter( i => i.primary ).length ).reduce( ( a, b ) => a + b, 0 ) );
  const instanceCount = computed( () => Object.values( issues.value ).map( t => t.issues.length ).reduce( ( a, b ) => a + b, 0 ) );

  const reportFragment = gql`
    fragment reportFragment on Report {
      _id
      identifier
      title

      issuesWithClosed {
        _id
        identifier
        primary
        reason

        priority
        status
        flag

        isAKeyIssueInstance

        reporter {
          _id
          name
        }

        assignee {
          _id
          name
        }

        template {
          _id
          identifier
          title

          severity
        }

        component {
          _id
          identifier
        }

        page {
          _id
          name
        }

        others {
          page {
            _id,
            name
          }
        }
      }

      currentVersion {
        _id
        published
        version
      }

      team {
        users {
          _id
          name
        }
      }
    }`;

  let reportQuery = gql`
      query Report($identifier: String!) {
        report: Report(identifier: $identifier) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;

  if( version.value ) {
    reportQuery = gql`
      query Report($identifier: String!, $version: String!) {
        report: ReportWithVersion(identifier: $identifier, version: $version) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;
  }

  const { refetch: refetchReport, onResult: onReport } = useQuery(
    reportQuery,
    {
      identifier,
      version,
    },
    {
      fetchPolicy: 'no-cache',
    },
  );

  onReport( ( { data } ) => {
    if( !data ) return;

    report.value = data.report;
    
    filterIssues();

    if( data?.report?.currentVersion?._id ) {
      // eslint-disable-next-line no-underscore-dangle
      store._actions.setReport[0]( data.report?.currentVersion._id );
    }
    reportLoading.value = false;
  } );

  const cleanRefetch = () => {
    reportLoading.value = true;
    refetchReport();
  };

  const closeFragment = () => {
    history.pushState(
      {},
      null,
      `/${config.reportRouterReplacement}s/${identifier.value}/${version.value}/issues`,
    );
    cleanRefetch();
  };
  
  const getSeverity = severity => {
    switch( severity ) {
      case 0: {
        return 'Advisory';
      }
      case 1: {
        return 'Low';
      }
      case 2: {
        return 'Medium';
      }
      case 3: {
        return 'High';
      }
      case 4: {
        return 'Critical';
      }
      default: {
        return 'Unknown';
      }
    }
  };

  const getPriority = priority => {
    switch( priority ) {
      case 0: {
        return 'Lowest';
      }
      case 1: {
        return 'Low';
      }
      case 2: {
        return 'Medium';
      }
      case 3: {
        return 'High';
      }
      case 4: {
        return 'Highest';
      }
      default: {
        return 'Unknown';
      }
    }
  };

  if( localStorage.getItem( 'prePopIssueSeverity' ) != null ) {
    const value = [ 'advisory', 'low', 'medium', 'high', 'critical' ].indexOf( localStorage.getItem( 'prePopIssueSeverity' ) );
    filters.value.severity.push( value.toString() );
    localStorage.removeItem( 'prePopIssueSeverity' );
  }

  const goToComponent = component => {
    componentfragment.value.show( component );
  };

  const goToPage = page => {
    pagefragment.value.show( page );
  };

  const goToUserJourney = journey => {
    userjourneyfragment.value.show( journey );
  };

  const goToIssue = issue => {
    issuefragment.value.show( issue );
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_colours.scss';
  .Issues {
    position: relative;

    &_Blurb {
      font-size: 0.9em;
    }

    &_Btns {
      position: absolute;
      right: 50px;
      top: 36px;

      button, a {
        margin-left: 8px;
      }
    }

    &_FilterAlert {
      font-family: $hugr-fonts-body;
      font-weight: bold;
      font-size: 0.9em;
    }
    
    &_FauxTable {
      &_Head {
        border-bottom: 4px solid $dig-blue;
        h4 {
          font-family: $hugr-fonts-logo;
          font-weight: bold;
          margin: 0;
          text-align: left;
          padding: 8px;
          padding-left: 0;
          padding-right: 16px;

          &:first-child {
            margin-left: 21px;
          }
        }
      }

      &_Templates {
        list-style: none;
        padding: 0;
        margin: 0;
        &_Item {
          &_Inner {
            border-bottom: 1px solid $hugr-colours-grey;
            &_Btn {
              border: none;
              margin: 0;
              background: transparent;

              padding: 4px;
              cursor: pointer;
            }
            &_TemplateTitle {
              text-decoration: underline;
              color: $hugr-colours-new-link;
              cursor: pointer;
            }
            p {
              display: table;
              margin: 0;
              padding: 0.8em 0;
              font-size: 0.8em;
              span {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
              }
            }
          }

          &_Sub {
            background: $hugr-colours-input-surface;
            &_Head {
              padding: 0 16px;
              h4 {
                font-family: "Quicksand", sans-serif;
                font-size: 10pt;
                font-weight: bold;
                margin: 0;
                padding: 0.8em 0;
                color: $hugr-colours-primary;
              }
            }
            &_Issues {
              list-style: none;
              padding: 0;
              margin: 0;
              &_Item {
                padding: 0 16px;
                // &:nth-child(odd) {
                //   background: lighten( $hugr-colours-grey, 14% );
                // }
                &_Inner {
                  p {
                    display: table;
                    margin: 0;
                    padding: 0.8em 0;
                    font-size: 0.8em;
                     > span {
                      display: table-cell;
                      vertical-align: middle;
                      width: 100%;
                    }
                  }
                  ._identifier {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ._darkMode .Issues {
    &_FauxTable {
      &_Templates {
        &_Item {
          &_Sub {
            background: darken( $hugr-colours-primary, 10% );
            &_Head {
              h4 {
                color: #FFF;
              }
            }
            &_Issues {
              &_Item {
                // &:nth-child(odd) {
                //   background: lighten( $hugr-colours-primary, 20% );
                // }
              }
            }
          }
          &_Inner {
            &_Btn {
              color: #FFF;
            }
            &_TemplateTitle {
              color: $dig-orange;
            }
          }
        }
      }
    }
  }
</style>
