<template>
  <div>
    <Body v-if="report" v-bind:title="report.title">
      <h3 v-translate>Score</h3>
      <Score :key="'score-'+report.currentVersion._id" v-bind:report="report.currentVersion._id" />

      <h3 v-translate>Conformance</h3>
      <Conformance v-bind:report="report.currentVersion._id" />

      <h3 v-translate>Report Contents</h3>

      <div v-if="sections" class="sections">
        <div v-for="(subsections, section) in sections" v-bind:key="'section-' + section">
          <h4>{{section}}</h4>
          <div v-for="(stemplates, subsection) in subsections" v-bind:key="'subsection-' + subsection">
            <h5>{{subsection}}</h5>
            <ul>
              <template v-for="(instances, issue) in issues">
                <li v-if="stemplates.templates.indexOf(issue)>=0" v-bind:key="'issue-' + issue">
                  <router-link :to="'/customer/issue/'+issue"><MapID v-bind:mID="issue" socketOptions="ISSUETEMPLATE" /> <translate :translate-params="{num: numOfInstances(instances)}"> (%{num} instances)</translate></router-link>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </Body>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import UIBody from '@/components/UI/Body';

import MapID from '@/components/Helpers/MapID';

import Score from '@/components/Score';
import Conformance from '@/components/Conformance';

export default {
  name: 'CustomerReportView',
  created() {
    this.jwtemit( [ 'GET_REPORT' ] );
    this.jwtemit( [ 'GET_SECTIONS' ] );
  },
  data() {
    return {
      report: false,
      owner: false, //TODO
      editor: true, //TODO

      pages: false,
      components: false,
      issues: false,
      sections: false,
    };
  },
  sockets: {
    STATUS_REPORT( report ) {
      this.report = report;
      this.jwtemit( [ 'GET_PAGES', this.report.currentVersion._id ] );
      this.jwtemit( [ 'GET_COMPONENTS', this.report.currentVersion._id ] );
      this.jwtemit( [ 'GET_ISSUES_LITE', this.report.currentVersion._id ] );
    },
    RETURN_PAGES( pages ) {
      this.pages = pages;
    },
    RETURN_COMPONENTS( components ) {
      this.components = components;
    },
    RETURN_ISSUES_LITE( issues ) {
      this.issues = issues;
    },
    RETURN_SECTIONS( sections ) {
      this.sections = sections;
    },
  },
  methods: {
    ...mapActions( [ 'jwtemit' ] ),
    numOfInstances( instances ) {
      let num = 0;
      for( const instance of instances ) {
        if( typeof instance.group != 'undefined' ) {
          if( instance.reason == null ) {
            num++;
          }
        } else {
          num++;
        }
      }

      return num;
    },
  },
  components: {
    Body: UIBody,
    MapID,
    Score,
    Conformance,
  },
};
</script>

<style lang="scss" scoped>

</style>
