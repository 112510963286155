<template>
  <div>
    <div v-if="score" class="score">
      <span class="total">{{ score.total }}</span>
      <span class="over">&nbsp;{{$gettext('out of a possible')}}&nbsp;</span>
      <span class="possible">{{ score.possible }}</span>
    </div>

    <PieGraph :key="score.total" :data="score.severity" :colours="colours"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import PieGraph from '@/components/Graph/Pie';

export default {
  name: 'Score',
  created() {
    this.jwtemit( [ 'GET_SCORE', this.report ] );
  },
  data() {
    return {
      score: false,
      colours: {
        advisory: '#cddc39',
        low: '#00bcd4',
        medium: '#ffeb3b',
        high: '#ff9800',
        critical: '#f44336',
      },
    };
  },
  sockets: {
    RETURN_SCORE( score ) {
      this.score = score;
    },
  },
  methods: {
    ...mapActions( [ 'jwtemit' ] ),
  },
  props: {
    report: {
      type: String,
      required: true,
    },
  },
  components: {
    PieGraph,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .score {
    font-family: 'Merriweather', serif;
    font-weight: bold;
    font-size: 60px;
    line-height: .8em;
    text-align: right;
    display: inline-block;
    color: #262e37;

    .total {
      display: block;
    }
    .possible {
      display: block;
      color: #5e646a;
    }
    .over {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
    }
  }
</style>
