<template>
  <WidgetBase title="Common issues">
    <div v-for="( issue, i ) in sorted" :key="'issue-'+i">
      <div v-if="i < 4" class="Issue">
        <div class="Issue_Half">
          <h5 class="Issue_Half_Title">
            {{issue.template.title}}
          </h5>
          <div class="Issue_Half_Criteria">
            {{ [ 'Advisory', 'Low', 'Medium', 'High', 'Critical' ][ issue.template.severity ] }}  -
            <span v-for=" (criterion, k) in issue.template.criteria" :key="`criteria-`+k">
              WCAG {{ criterion.criterion }} ({{ criterion.level }})
            </span>
          </div>
        </div>
        <div class="Issue_Half">
          <div class="Bar">
            <div class="Bar_Inner" :style="`width: ${issue.instances / sorted[0].instances * 100}%`"></div>
          </div>
          <div class="Issue_Half_Count">
            {{ issue.instances }} instance(s) on {{ issue.products }} product(s)
          </div>
        </div>
      </div>
    </div>
    <Empty v-if="data.length == 0" :text="$gettext( 'No issues on any assessments' )" />
  </WidgetBase>
</template>

<script>
import WidgetBase from './WidgetBase.vue';

export default {
  name: 'UIWidgetCommonIssues',
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    sorted() {
      return [ ...this.data ].sort( ( a,b ) => b.instances - a.instances );
    },
  },
  components: {
    WidgetBase,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Bar {
    width: 100%;
    height: 16px;
    margin-bottom: 8px;

    &_Inner {
      height: 16px;
      background-color: $hugr-colours-pills-critical;
    }
  }

  .Issue {
    display: flex;
    margin-bottom: 16px;

    &_Half {
      width: 48%;
      margin-right: 8px;

      &_Title {
        margin-bottom: 8px;
        margin-top: 0;
        font-weight: bold;
        font-size: 11pt;
      }

      &_Count {
        font-size: 10pt;
        margin-top: -4px;
      }

      &_Criteria {
        font-size: 10pt;
        margin-top: -6px;
      }
    }
  }
  // .widget {
  //   .container {
  //     padding: 20px;
  //     .inner {
  //       height: 100%;
  //       position: relative;
  //     }
  //   }
  // }
</style>
