<template>
  <div>
    <div class="pie">
      <div v-for="(segment,index) in graph" class="segment" :style="'--offset: '+segment.offset+'; --value: '+segment.value+'; --bg: '+segment.colour+';'" v-bind:key="'segment-' + index">
        <!-- <span class="read">{{segment.read}}</span> -->
      </div>
    </div>
    <ul class="key">
      <li v-for="(segment,index) in graph" v-bind:key="'segment-' + index">
        <span class="colour" :style="'background:'+segment.colour"></span>{{segment.read}}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'PieGraph',
  data() {
    const graph = [];
    let offset = 0;
    let total = 0;
    for( const key of Object.keys( this.data ) ) {
      total += parseInt( this.data[key] );
    }
    for( const key of Object.keys( this.data ) ) {
      const value = parseInt( ( ( this.data[key] / total ) * 100 ).toFixed( 0 ) );
      let colour = this.getRandomColor();
      if( this.colours ) {
        colour = this.colours[key];
      }
      graph.push( {
        read: `${key}: ${this.data[key]} (${value}%)`,
        value,
        offset,
        colour,
      } );
      offset += value;
    }

    return {
      graph,
    };
  },
  methods: {
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for ( let i = 0; i < 6; i++ ) {
        color += letters[Math.floor( Math.random() * 16 )];
      }

      return color;
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
        advisory: 20,
        critical: 20,
        high: 20,
        low: 20,
        medium: 20,
        };
      },
    },
    colours: {
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  .pie {
    display: inline-block;
    vertical-align: top;
    margin-right: 50px;

    border-radius: 100%;
    height: calc(var(--size, 200) * 1px);
    overflow: hidden;
    position: relative;
    width: calc(var(--size, 200) * 1px);
    .segment {
      --a: calc(var(--over50, 0) * -100%);
      --b: calc((1 + var(--over50, 0)) * 100%);
      --degrees: calc((var(--offset, 0) / 100) * 360);
      -webkit-clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
      clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
      height: 100%;
      position: absolute;
      transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
      transform-origin: 50% 100%;
      width: 100%;
      z-index: calc(1 + var(--over50));

      &:after, &:before {
        background: var(--bg, #e74c3c);
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
      }

      &:before {
        --degrees: calc((var(--value, 45) / 100) * 360);
        transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
        transform-origin: 50% 0%;
      }

      &:after {
        opacity: var(--over50, 0);
      }

      .read {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
      }
    }
  }

  ul.key {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    margin: 0;
    border: 1px solid #cbd2da;
    padding: 5px;
    li {
      .colour {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
</style>
