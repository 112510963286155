<template>
  <div class="ActivityLog">
    <h3 class="ActivityLog_Title">Activity Log</h3>
    <ul :class="['ActivityLog_List', posting?'_posting':'']" v-if="log.length">
      <template v-for="logItem in log" >
        <li class="ActivityLog_List_Item" v-if="!logItem.hidden" :key="logItem.timestamp">
          <Avatar :uid="logItem.user._id" type="round"/>
          <div class="ActivityLog_List_Item_Container">
            <div class="ActivityLog_List_Item_Container_Header">
              <p class="ActivityLog_List_Item_Container_Header_Name">{{ logItem.user.name }}</p>
              <span class="ActivityLog_List_Item_Container_Header_Time">{{ moment( logItem.timestamp ).fromNow() }}</span>
            </div>
            <div class="ActivityLog_List_Item_Container_Body">
              <!-- report created -->
              <template v-if="logItem.type==0">
                <p v-translate>Created this assessment</p>
              </template>

              <!-- report archived -->
              <template v-if="logItem.type==1">
                <p v-translate>Archived this assessment</p>
              </template>

              <!-- report unarchived -->
              <template v-if="logItem.type==2">
                <p v-translate>Unarchived this assessment</p>
              </template>

              <!-- report published -->
              <template v-if="logItem.type==3">
                <p>Published version {{ logItem.extra }}</p>
              </template>

              <!-- report unpublished -->
              <template v-if="logItem.type==4">
                <p>Unpublished version {{ logItem.extra }}</p>
              </template>

              <!-- report title change -->
              <template v-if="logItem.type==5">
                <p><span v-translate>Changed the assessments title</span> {{ logItem.extra }}</p>
              </template>

              <!-- report customer change -->
              <template v-if="logItem.type==6">
                <p><span v-translate>Changed the assessments customer</span> {{ logItem.extra }}</p>
              </template>

              <!-- report owner change -->
              <template v-if="logItem.type==7">
                <p><span v-translate>Changed the assessments owner</span> {{ logItem.extra }}</p>
              </template>

              <!-- report team change -->
              <template v-if="logItem.type==8">
                <p><span v-translate>Changed the team on assessment</span> {{ logItem.extra }}</p>
              </template>

              <!-- report portfolio change -->
              <template v-if="logItem.type==9">
                <p><span v-translate>Changed the portfolio on assessment</span> {{ logItem.extra }}</p>
              </template>

              <!-- report team removed -->
              <template v-if="logItem.type==10">
                <p><span v-translate>Removed the assessment from team</span> {{ logItem.extra }}</p>
              </template>

              <!-- report portfolio removed -->
              <template v-if="logItem.type==11">
                <p><span v-translate>Removed the assessment from portfolio</span> {{ logItem.extra }}</p>
              </template>

              <!-- report team added -->
              <template v-if="logItem.type==12">
                <p><span v-translate>Added the assessment to team</span> {{ logItem.extra }}</p>
              </template>

              <!-- report portfolio added -->
              <template v-if="logItem.type==13">
                <p><span v-translate>Added the assessment to portfolio</span> {{ logItem.extra }}</p>
              </template>

              <!-- report page added -->
              <template v-if="logItem.type==14">
                <p>Added page {{ logItem.extra }} to the <span v-translate>assessment</span></p>
              </template>

              <!-- report page edited -->
              <template v-if="logItem.type==15">
                <p>Edited page details on {{ logItem.extra }}</p>
              </template>

              <!-- report page removed -->
              <template v-if="logItem.type==16">
                <p>Removed page {{ logItem.extra }} from the <span v-translate>assessment</span></p>
              </template>

              <!-- report component added -->
              <template v-if="logItem.type==17">
                <p>Added component {{ logItem.extra }} to the <span v-translate>assessment</span></p>
              </template>

              <!-- report component edited -->
              <template v-if="logItem.type==18">
                <p>Edited component details on {{ logItem.extra }}</p>
              </template>

              <!-- report component removed -->
              <template v-if="logItem.type==19">
                <p>Removed component {{ logItem.extra }} from the <span v-translate>assessment</span></p>
              </template>

              <!-- Issue created -->
              <template v-if="logItem.type==20">
                <p>A new issue was created at <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link></p>
                <p class="ActivityLog_List_Item_Container_Body_Sub">{{ logItem.issue.template.identifier }}: {{ logItem.issue.template.title }}</p>
              </template>

              <!-- Issue comment created -->
              <template v-if="logItem.type==21">
                <p>Added a comment to issue <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link></p>
                <vue-markdown class="ActivityLog_List_Item_Container_Body_Sub" :key="`${logItem.timestamp}-comment`" v-highlight :anchorAttributes="{target: '_blank'}" :html="true">{{ logItem.extra }}</vue-markdown>
              </template>

              <!-- report comment created -->
              <template v-if="logItem.type==22">
                <p v-if="logItem.child && logItem.child.type==37" v-translate>Added a note to the assessment with attachment</p>
                <p v-else v-translate>Added a note to the assessment</p>
                <vue-markdown class="ActivityLog_List_Item_Container_Body_Sub" :key="`${logItem.timestamp}-comment`" v-highlight :anchorAttributes="{target: '_blank'}" :html="true">{{ logItem.extra }}</vue-markdown>
                <template v-if="logItem.child && logItem.child.type==37">
                  <div v-if="JSON.parse(logItem.child.extra).length > 0" class="Comments_Comment_Files">
                    <li v-for="(file, i) of JSON.parse(logItem.child.extra)" :key="`comment-${_id}-${i}`">
                      <span> <a href="#" @click="openFile(file.uuid, file.name)">{{deUrify(file.name)}}</a> ({{returnFileSize(file.size)}})</span>
                    </li>
                  </div>
                </template>
              </template>

              <!-- issue status changed -->
              <template v-if="logItem.type==23">
                <p>Changed the status of <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> {{ logItem.extra }}</p>
              </template>

              <!-- issue flag (qa) changed -->
              <template v-if="logItem.type==24">
                <p>Changed the QA status of <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> {{ logItem.extra }}</p>
              </template>

              <!-- issue priority changed -->
              <template v-if="logItem.type==25">
                <p>Changed the issue priority of <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> {{ logItem.extra }}</p>
              </template>

              <!-- issue evidence changed -->
              <template v-if="logItem.type==26">
                <p>Updated the evidence on <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> {{ logItem.extra }}</p>
              </template>

              <!-- issue reason changed -->
              <template v-if="logItem.type==27">
                <p>Updated the reasoning on <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link></p>
                <p class="ActivityLog_List_Item_Container_Body_Sub">{{ logItem.extra }}</p>
              </template>

              <!-- issue solution added-->
              <template v-if="logItem.type==28">
                <p>Added a solution to <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link></p>
                <p class="ActivityLog_List_Item_Container_Body_Sub">{{ logItem.extra }}</p>
              </template>

              <!-- issue solution removed-->
              <template v-if="logItem.type==29">
                <p>Removed a solution from <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link></p>
                <p class="ActivityLog_List_Item_Container_Body_Sub">{{ logItem.extra }}</p>
              </template>

              <!-- issue page added-->
              <template v-if="logItem.type==30">
                <p>Added <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> to {{ logItem.extra }}</p>
              </template>

              <!-- issue page remove-->
              <template v-if="logItem.type==31">
                <p>Removed <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> from {{ logItem.extra }}</p>
              </template>

              <!-- issue assigned-->
              <template v-if="logItem.type==32">
                <p>Assigned <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> to {{ logItem.extra }}</p>
              </template>

              <!-- issue unassigned-->
              <template v-if="logItem.type==33">
                <p>Unassigned <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link></p>
              </template>

              <!-- issue set key-->
              <template v-if="logItem.type==34">
                <p>Set <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> as key issue</p>
              </template>

              <!-- issue unset key-->
              <template v-if="logItem.type==35">
                <p>Unset <router-link target="_blank" :to="`/issue/instance/${logItem.issue.identifier}`">{{ logItem.issue.identifier }}</router-link> as key issue</p>
              </template>

              <!-- new version-->
              <template v-if="logItem.type==36">
                <p>Created a new version {{ logItem.extra }}</p>
              </template>

              <!-- file uploaded - is hidden and incorporated into 22 if adjacent -->
              <template v-if="logItem.type==37">
                <p>Uploaded files</p>
                <div v-if="JSON.parse(logItem.extra).length > 0" class="Comments_Comment_Files">
                  <li v-for="(file, i) of JSON.parse(logItem.extra)" :key="`comment-${_id}-${i}`">
                    <span> <a href="#" @click="openFile(file.uuid, file.name)">{{deUrify(file.name)}}</a> ({{returnFileSize(file.size)}})</span>
                  </li>
                </div>
              </template>

              <!-- report sensitivity -->
              <template v-if="logItem.type==38">
                <p><span v-translate>Marked the assessment as</span> {{ logItem.extra }}</p>
              </template>

              <template v-if="logItem.type==39">
                <p v-translate>Exported issues to CSV</p>
              </template>
            </div>
          </div>
        </li>
      </template>
    </ul>
    <!-- :fileUploadEnabled="true" :reportID="report" -->
    <Comments v-if="posting" :reference="report" :team="team" :boxOnly="true" noun="note" verb="post" @posted="handlePosted"  :fileUploadEnabled="hasPermission('Files','Upload')" :reportID="report"/>
    <div class="ActivityLog_Btns __grid">
      <Button :class="[!hasMore&&page==0?'__grid_column_12':'__grid_column_4']" size="micro" @click="posting=!posting">{{ posting?'Cancel':'Add note' }}</Button>
      <Button class="__grid_column_4" v-if="hasMore||page!=0" size="micro" type="secondary" :disabled="page==0" @click="page=page==0?0:page-1" :icon="['solid', 'chevron-left']">
        <span>Previous</span>
      </Button>
      <Button class="__grid_column_4" v-if="hasMore||page!=0" size="micro" type="secondary" :disabled="!hasMore" @click="page=hasMore?page+1:page" :icon="['solid', 'chevron-right', 'after']">
        <span>Next</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { toRefs, ref, computed, getCurrentInstance } from 'vue';
  import { useQuery } from '@vue/apollo-composable';
  import gql from 'graphql-tag';
  import moment from 'moment';
  import { useStore } from 'vuex';

  import Avatar from '@/components/Helpers/Avatar.vue';

  import Comments from '@/components/Comments/Index';

  const store = useStore();
  const hasPermission = computed( () => store.getters.hasPermission );

  const props = defineProps( {
    report: String,
    team: String,
  } );

  const { report, team } = toRefs( props );
  const page = ref( 0 );
  const size = ref( 6 );

  const log = ref( [] );
  const hasMore = ref( false );

  const posting = ref( false );

  const { onResult, refetch } = useQuery( gql`
    query ActivityLogPage($report: ObjectID!, $page: Int!, $size: Int!) {
      log: ActivityLogPage(report: $report, page: $page, size: $size) {
        engagements {
          user {
            _id
            name
          }
          issue {
            _id
            identifier
            template {
              identifier
              title
            }
          }
          timestamp
          type
          extra
        }
        hasMore
        pages
      }
    }
  `,
  {
    report,
    page,
    size,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  const deUrify = name => {
      return decodeURI( name );
  };
  const app = getCurrentInstance();
  const openFile = ( uuid, name ) => {
      const url = `${app.appContext.config.globalProperties.$hugrConfig.fileContainerURL}/download/${uuid}/${name}`;

      fetch( `${url}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem( "HUGR_ACCESS_TOKEN" )}`,
        },
      } ).then( res => {
        if ( res.ok ) {
          return res.blob();
        }

        return res.text();
      } ).then( data => {
        if ( data instanceof Blob ) {
          const url = window.URL.createObjectURL( data );
          const a = document.createElement( "a" );
          a.style.display = 'none';
          a.setAttribute( 'href', url );
          a.setAttribute( 'download', name );
          document.getElementById( 'app' ).append( a );
          a.click();
          window.URL.revokeObjectURL( url );
          a.remove();
        } else {
          this.$alerts.error( "Failure", data );
        }
      } ).catch( () => {
        
      } );
    };

    const returnFileSize = number => {
      if ( number < 1024 ) {
        return `${number} bytes`;
      } else if ( number >= 1024 && number < ( 1024 * 1024 ) ) {
        return `${( number / 1024 ).toFixed( 1 )} KB`;
      } else if ( number >= ( 1024 * 1024 ) ) {
        return `${( number / ( 1024 * 1024 ) ).toFixed( 1 )} MB`;
      }
    };

  onResult( ( { data } ) => {
    // log.value = [ ...log.value, ...data.log.engagements ];
    log.value = data.log.engagements;

    //group file with comment
    for( const index in log.value ) {
      if( log.value.hasOwnProperty( index ) //is actual item
          && log.value[ parseInt( index ) + 1 ] //has next item
          // && [ 22, 37 ].indexOf( log.value[ index ].type ) >= 0 //is a comment or file upload
          // && [ 22, 37 ].indexOf( log.value[ parseInt( index ) + 1 ].type ) >= 0 //next item is a comment or file upload
          && [ 37 ].indexOf( log.value[ index ].type ) >= 0 //first is upload
          && [ 22 ].indexOf( log.value[ parseInt( index ) + 1 ].type ) >= 0 //second is comment
          && log.value[ index ].type != log.value[ parseInt( index ) + 1 ].type //not the same type
        ) {
        let parent = log.value[ parseInt( index ) + 1 ];
        let child = log.value[ index ];
        if( parent.type == 37 ) {
          parent = log.value[ index ];
          child = log.value[ parseInt( index ) + 1 ];
        }

        if( parent.extra == '' ) {
          parent.hidden = true;
        } else {
          parent.child = child;
          child.hidden = true;
        }
      }
    }

    hasMore.value = data.log.hasMore;
  } );

  const reload = () => {
    refetch();
  };

  const emit = defineEmits( [ 'newItem' ] );

  const handlePosted = () => {
    posting.value = false;
    refetch();
    emit( 'newItem' );
  };

  defineExpose( {
    reload,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .ActivityLog {
    border-left: 1px solid lighten( $hugr-colours-grey, 10% );
    padding-left: 16px;
    // margin: 16px 0px;

    &_Title {
      font-size: 1.1em;
      font-weight: bold;
      margin: 0;
    }

    &_List {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 16px;
      min-height: calc( 100vh - 450px );

      &._posting {
        min-height: unset;
      }

      &_Item {
        margin-bottom: 16px;
        > * {
          display: inline-block;
          vertical-align: top;
        }
        &_Container {
          width: calc( 100% - 38px);
          &_Header {
            position: relative;
            > * {
              display: inline-block;
              vertical-align: middle;
            }
            &_Name {
              margin: 0;
              margin-left: 8px;
              font-size: 0.8em;
              font-weight: bold;
            }
            &_Time {
              font-size: 0.6em;
              position: absolute;
              right: 4px;
              top: 4px;
            }
          }

          &_Body {
            padding: 8px;
            font-size: 0.8em;
            p { margin: 0 }
            &_Sub {
              background: lighten( $dig-blue, 70% );
              padding: 6px;
              border-radius: 6px;
              margin-top: 8px !important;
              font-size: 10pt;
              color: #000;
            }
          }
        }
      }
    }

    &_Btns {
      width: 100%;
      text-align: center;
      button {
        text-align: center;
        svg {
          margin: 0 8px;
          font-size: 0.8em;
        }
      }
    }
  }

  ._darkMode .ActivityLog {
    &_List {
      &_Item {
        &_Container {
          &_Body {
            &_Sub {
              color: #000;
            }
          }
        }
      }
    }
  }
</style>
