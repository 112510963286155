<template>
  <ReportInfo :trail="[
      {name: $gettext('Home'), path: '/dashboard'},
      {name: $gettext('Assessments'), path: `/${$hugrConfig.reportRouterReplacement}s`},
      {name: 'THIS_REPORT'},
      {name: $gettext('Pages'), path: `/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/pages`}
    ]" />

  <Body :withMenu="true" class="Pages" title="Pages" ref="body" :titleIcon="['regular','file']" :titleHint="['',$gettext('Pages are the individual screens you are reviewing as part of this product. Screens may be part of a user journey and may also contain components.')]">
    <div class="Pages_Btns" v-if="!reportLoading">
      <Button v-if="!report.report.currentVersion.published" class="Pages_Btns_Add" :icon="['regular', 'file']" size="small" type="secondary" @click="pagemodal.show()">{{ $gettext('Add a page') }}</Button>
      <LinkButton class="Pages_Btns_Spreadsheet" :icon="['solid', 'table']" size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/spreadsheet`">Spreadsheet view</LinkButton>
    </div>

    <template v-if="!reportLoading">
      <DataTable
          v-if="report.report.pages.length>0"
          :noScroll="true"
          class="Pages_Table"
          :headers="[
            $gettext('Page name'),
            // $gettext('Page title'),
            $gettext('URL'),
            $gettext('Components'),
            $gettext('Test status'),
            $gettext('Compliance'),
            $gettext('Instances of issues by severity'),
          ]"
        >
        <tr v-for="page in report.report.pages" :key="page._id" class="Pages_Table_Row">
          <td class="Pages_Table_Row_Name"><router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${identifier}/${version}/pages/${page._id}`" @click="pagefragment.show( page._id )" @keyup.space.prevent="pagefragment.show( page._id )" :title="page.name">{{ page.name }}</router-link></td>
          <!-- <td class="Pages_Table_Row_Title">
            <span v-if="page.title" :title="page.title">{{ page.title }}</span>
            <span v-else>Untitled</span>
          </td> -->
          <td class="Pages_Table_Row_Url">
            <span v-if="page.host||page.path" :title="`${page.host}${page.path}`"><Link :external="true" :href="`${page.host}${page.path}`" :icon="true" :iconOnly="true" target="_blank">{{ page.host }}{{ page.path }}</Link></span>
            <span v-else>No URL</span>
          </td>
          <td>{{ page.componentsCount }}</td>
          <td class="Pages_Table_Row_Progress"><ProgressBar :id="`pageprogress-${page.name}`" label="Page Progress" :hideLabel="true" :type="'simple'" :value="page.progress"/></td>
          <td>
            <CompliancePill :progress="page.progress" :isCompliant="page.isCompliant" :issues="page.issuesCount" size="small"/>
          </td>
          <td>
            <SeverityLine
              :type="hasPermission( 'UIAlts', 'IssueBySeverityBar' )?'line':'pills'"
              :advisory="page.issues.filter( i => i.issueTemplate.severity == 0 ).length"
              :low="page.issues.filter( i => i.issueTemplate.severity == 1 ).length"
              :medium="page.issues.filter( i => i.issueTemplate.severity == 2 ).length"
              :high="page.issues.filter( i => i.issueTemplate.severity == 3 ).length"
              :critical="page.issues.filter( i => i.issueTemplate.severity == 4 ).length"
              />
          </td>
        </tr>
      </DataTable>
      <Empty v-else :text="$gettext('No pages')"
                  :button="{size: 'regular', type: 'secondary', icon: ['regular', 'file'], text: $gettext('Add an page')}"
                  @buttonClick="$refs.pagemodal.show()" />
    </template>
    <Loader v-else />
  </Body>
  <PageModal v-if="!reportLoading" :report="report.report.currentVersion._id" :components="report.report.components" ref="pagemodal" @added="preRefetchReport"/>
  
  <PageFragment ref="pagefragment" @closed="closeFragment" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
  <ComponentFragment ref="componentfragment" @closed="closeFragment" @goToPage="goToPage" @goToUserJourney="goToUserJourney" @goToIssue="goToIssue"/>
  <UserJourneyFragment ref="userjourneyfragment" @closed="closeFragment" @goToComponent="goToComponent" @goToPage="goToPage" @goToIssue="goToIssue"/>
  <IssueFragment ref="issuefragment" @closed="closeFragment" @goToPage="goToPage" @goToComponent="goToComponent" @goToUserJourney="goToUserJourney"/>
</template>

<script setup>
  import PageModal from '@/modals/Page/Create';

  import PageFragment from '@/fragments/Page';
  import ComponentFragment from '@/fragments/Component';
  import UserJourneyFragment from '@/fragments/UserJourney';
  import IssueFragment from '@/fragments/Issue';

  import ProgressBar from '@/components/UI/ProgressBar';
  import CompliancePill from '@/components/CompliancePill';
  import Pill from '@/components/UI/Pill.vue';
  import SeverityLine from '@/components/UI/SeverityLine';

  import Link from '@/components/Helpers/Link';

  import { ref, onMounted, computed, vModelDynamic } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuery, useMutation } from '@vue/apollo-composable';
  import gql from 'graphql-tag';

  import config from '../../../config';

  const router = useRouter();
  const store = useStore();

  const hasPermission = computed( () => store.getters.hasPermission );

  const pagemodal = ref( null );

  const pagefragment = ref( null );
  const componentfragment = ref( null );
  const userjourneyfragment = ref( null );
  const issuefragment = ref( null );

  const identifier = ref( router.currentRoute.value.params.identifier );
  const version = ref( router.currentRoute.value.params.version );
  const urlpage = ref( router.currentRoute.value.params.page );

  const body = ref( null );

  onMounted( () => {
    if( urlpage.value ) {
      pagefragment.value.show( urlpage.value );
    }

    const { mutate: sendNavigation } = useMutation(
      gql`
        mutation sendNavigation ($page: String) {
          sendNavigation(page: $page)
        }
      `, {
        variables: {
          page: 'Assessment Pages',
        },
    } );
    
    sendNavigation();
  } );

  const reportFragment = gql`
    fragment reportFragment on Report {
      _id
      identifier
      title

      pages {
        _id
        host
        path
        name
        title
        progress
        isCompliant

        componentsCount
        issuesCount
        issues {
          issueTemplate {
            severity
          }
        }
      }

      components { 
        _id,
        identifier,
        description,
      }

      currentVersion {
        _id
        published
      }
    }`;

  let reportQuery = gql`
      query Report($identifier: String!) {
        report: Report(identifier: $identifier) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;

  if( version.value ) {
    reportQuery = gql`
      query Report($identifier: String!, $version: String!) {
        report: ReportWithVersion(identifier: $identifier, version: $version) {
          ... reportFragment
        }
      }
      ${reportFragment}
    `;
  }

  const { result: report, refetch: refetchReport, loading: reportLoading, onResult: onReport } = useQuery(
    reportQuery,
    {
      identifier,
      version,
    },
  );

  const preRefetchReport = () => {
    refetchReport();
    body.value.reloadMenu();
  };

  onReport( ( { data } ) => {
    if( data?.report?.currentVersion?._id ) {
      // eslint-disable-next-line no-underscore-dangle
      store._actions.setReport[0]( data.report.currentVersion._id );
    }
  } );

  const closeFragment = () => {
    history.pushState(
      {},
      null,
      `/${config.reportRouterReplacement}s/${identifier.value}/${version.value}/pages`,
    );
    refetchReport();
    body.value.reloadMenu();
  };

  const goToComponent = component => {
    componentfragment.value.show( component );
  };

  const goToPage = page => {
    pagefragment.value.show( page );
  };

  const goToUserJourney = journey => {
    userjourneyfragment.value.show( journey );
  };

  const goToIssue = issue => {
    issuefragment.value.show( issue );
  };

</script>

<style lang="scss" scoped>
  .Pages {
    position: relative;

    &_Blurb {
      font-size: 0.9em;
    }

    &_Btns {
      position: absolute;
      right: 50px;
      top: 36px;

      button, a {
        margin-left: 8px;
      }
    }
    
    &_Table {
      margin-top: 32px;

      &_Row {
        &_Name {
          max-width: 10vw;
          a {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &_Title {
          max-width: 10vw;
          span {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &_Url {
          max-width: 20vw;
          span {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &_Progress {
          width: 130px;
        }
      }
    }
  }
</style>
