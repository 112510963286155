<template>
  <div v-if="true" class="conformance">
    <span :class="['level', 'a', (conformance.A?'pass':'fail')]">A<span class="sr-only"> {{ (conformance.A?'Pass':'Fail') }}</span></span>
    <span :class="['level', 'aa', (conformance.AA?'pass':'fail')]">AA<span class="sr-only"> {{ (conformance.AA?'Pass':'Fail') }}</span></span>
    <span :class="['level', 'aaa', (conformance.AAA?'pass':'fail')]">AAA<span class="sr-only"> {{ (conformance.AAA?'Pass':'Fail') }}</span></span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Conformance',
  created() {
    this.jwtemit( [ 'GET_CONFORMANCE', this.report ] );
  },
  data() {
    return {
      conformance: false,
    };
  },
  sockets: {
    RETURN_CONFORMANCE( c ) {
      this.conformance = c;
    },
  },
  methods: {
    ...mapActions( [ 'jwtemit' ] ),
  },
  props: {
    report: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .conformance {
    font-family: 'Merriweather', serif;
    font-weight: bold;
    font-size: 60px;
    line-height: .8em;
    text-align: right;
    display: inline-block;
    color: #262e37;

    .level {
      margin-right: 20px;
      &.pass {
        color: #2C651C;
      }
      &.fail {
        color: #A9261B;
      }
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
    }
  }
</style>
