<template>
  <Body :title="$gettext('Reports')">
    <CardSet hlevel="3" :title="$gettext('My Reports')">
      <Card v-for="(report,index) in reports" v-bind:key="'report-' + index">
        <h4>{{ report.title }}</h4>
        <ButtonSet direction="vertical" size="small">
          <button class="button secondary" @click="open(report)">{{$gettext('Open')}}</button>
        </ButtonSet>
      </Card>
    </CardSet>
  </Body>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import UIBody from '@/components/UI/Body';
import UICardSet from '@/components/UI/CardSet';
import UICard from '@/components/UI/Card';
import UIButtonSet from '@/components/UI/ButtonSet';
import config from '../../../config';

export default {
  name: 'CustomerIndexView',
  created() {
    this.jwtemit( [ 'GET_REPORTS_BY_CUSTOMER', this.user.customer ] );
  },
  data() {
    return {
      reports: false,
    };
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  sockets: {
    RETURN_REPORTS_BY_CUSTOMER( reports ) {
      this.reports = reports;
    },
  },
  methods: {
    ...mapActions( [ 'jwtemit' ] ),
    open( report ) {
      this.jwtemit( [ 'SET_REPORT', report._id ] );
      this.jwtemit( [ 'SET_LEFTBAR_VIEW', 'customer-report' ] );
      this.$router.push( `/customer/${config.reportRouterReplacement}/${report._id}` );
    },
  },
  components: {
    Body: UIBody,
    CardSet: UICardSet,
    Card: UICard,
    ButtonSet: UIButtonSet,
    // MapID
  },
};
</script>
